// loginContext.tsx
import React, { useState } from "react";
import { useSessionStorage } from "usehooks-ts";

type TContext = {
  isLogin: boolean;
  userType: string | null;
  employeeName: string | null;
  toggleLogin: () => void;
  setUserType: (type: string) => void;
  setEmployeeName: (name: string) => void;
};

const LoginContext = React.createContext<TContext>( {
  isLogin: false,
  userType: null,
  employeeName: null,
  toggleLogin: () => {},
  setUserType: () => {},
  setEmployeeName: () => {},
});

export const LoginContextProvider: React.FC = (props) => {
  const [isLogin, setIsLogin] = useSessionStorage("isLogin", false);
  const [userType, setUserType] = useState<string | null>(null);
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  function toggleLogin() {
    setIsLogin((prev) => !prev);
  }

  const loginValue: TContext = {
    isLogin,
    userType,
    employeeName,
    toggleLogin,
    setUserType,
    setEmployeeName,
  };

  return (
      <LoginContext.Provider value={loginValue}>
        {props.children}
      </LoginContext.Provider>
  );
};

export default LoginContext;
