const sidebarNav = [
    {
        link: "/Dashboard",
        section: "Dashboard",
        icon: "lucide:layout-dashboard",
        text: "Dashboard",
        adminOnly: false,
    },
    {
        link: "",
        section: "Employees",
        icon: "ph:users-bold",
        text: "Employees",
        adminOnly: true,
        submenu:[
            {
                link: "/employees/all",
                text: "All Employees----",
            },
            {
                link: "/employees/active",
                text: "Active Employees--",
            },
            {
                link: "/employees/inactive",
                text: "Inactive Employees",
            }
        ]
    },{
        link: "/appr_pending",
        section: "Pending Approvals",
        icon: "fluent:approvals-app-24-regular",
        text: "Approvals",
        adminOnly: true,
    },
    {
        link: "/projects",
        section: "Projects",
        icon: "icon-park-outline:ad-product",
        text: "Projects",
        adminOnly: false,
    },
    {
        link: "/attendance",
        section: "Attendance",
        icon: "ion:book-outline",
        text: "Attendance",
        adminOnly: false,
    },
    {
        link: "/task_assign",
        section: "Assign Task",
        icon: "material-symbols:assignment-add-rounded",
        text: "Assign Task",
        adminOnly: true,
    },
    {
        link: "/leave_mgmt",
        section: "Leave Management",
        icon: "ic:round-inventory",
        text: "Leave Management",
        adminOnly: false,
    },
    {
        link: "/holidays",
        section: "Holidays",
        icon: "clarity:on-holiday-line",
        text: "Holidays",
        adminOnly: false,
    }
];

export default sidebarNav;
