import React, { useContext } from "react";
import { Icon } from "@iconify/react";
import { useWindowSize } from "usehooks-ts";
import SidebarContext from "../../store/sidebarContext";
import ThemeContext from "../../store/themeContext";
import LoginContext from "../../store/loginContext"; // Import the LoginContext
import classes from "./TopNav.module.scss";
import ThemeBox from "./rightBox/themeBox/ThemeBox";

function TopNav() {
  const sideOpenCtx = useContext(SidebarContext);
  const { width } = useWindowSize();
  const { theme } = useContext(ThemeContext);
  const loginCtx = useContext(LoginContext); // Access login context to get user details

  function openSidebarHandler() {
    sideOpenCtx.toggleSidebar();
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  const notificationIconColor = theme === "dark" ? "white" : "black";

  // Get the employee name from context
  const employeeName = loginCtx.employeeName;

  return (
      <div className={classes.topNav}>
        <div className={classes.topNav_left}>
          <div className={classes.topNav_left_menu_icon} onClick={openSidebarHandler}>
            <Icon icon="ci:menu-alt-03" width="24" />
          </div>
          <div className={classes.topNav_left_menu} onClick={openSidebarHandler}>
            <div className="topNav_left_menu_open">
              <Icon icon="ci:menu-alt-03" width="24" />
            </div>
            <div className="topNav_left_menu_close">
              <Icon icon="eva:close-fill" width="24" />
            </div>
          </div>
        </div>
        <Icon
            icon="mingcute:notification-line"
            style={{ color: notificationIconColor, height: "30px", width: "30px", alignItems: "flex-end" }}
        />
        <ThemeBox />
        {employeeName && <span className={classes.userName}>Hi {employeeName.toUpperCase()}!</span>}
      </div>
  );
}

export default TopNav;
