import React, { useState } from "react";
import classes from "./Upload.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/UI/button/Button";

const Upload = () => {
    const [files, setFiles] = useState<{ [key: string]: File | null }>({
        photo: null,
        aadhar: null,
        bank_passbook: null,
        marksheet_10: null,
        marksheet_12: null,
        grad_marksheet: null,
        pg_marksheet: null,
        pan_id: null,
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = event.target;
        const file = files ? files[0] : null;
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();

        for (const key in files) {
            if (files[key]) {
                formData.append(key, files[key]!); // Non-null assertion since we check null
            }
        }

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/Employee/Register/upload`, {
                method: "POST",
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Upload failed. Please try again.");
            }

            const data = await response.json();
            if (data.error === "0") {
                navigate("/success"); // Navigate to a success page or dashboard
            } else {
                setErrorMessage("Upload failed. Please check your details.");
            }
        } catch (error: any) {
            setErrorMessage(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.iitLogo}>
                <img src={require("../../assets/images/IIT K Logo.png")} alt="IIT Logo" style={{ width: '150px', marginBottom: '15px' }} />
            </div>
            <h2>Upload Documents</h2>
            {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
            <form onSubmit={handleSubmit}>
                <div className={classes.inputGroup}>
                    <label htmlFor="photo">Profile Photo:</label>
                    <input type="file" name="photo" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="aadhar">Aadhar Card:</label>
                    <input type="file" name="aadhar" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="bank_passbook">Bank Passbook:</label>
                    <input type="file" name="bank_passbook" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="marksheet_10">10th Marksheet:</label>
                    <input type="file" name="marksheet_10" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="marksheet_12">12th Marksheet:</label>
                    <input type="file" name="marksheet_12" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="grad_marksheet">Graduate Marksheet:</label>
                    <input type="file" name="grad_marksheet" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="pg_marksheet">Postgraduate Marksheet:</label>
                    <input type="file" name="pg_marksheet" accept="image/*" onChange={handleFileChange} />
                </div>
                <div className={classes.inputGroup}>
                    <label htmlFor="pan_id">PAN ID:</label>
                    <input type="file" name="pan_id" accept="image/*" onChange={handleFileChange} />
                </div>
                <Button type="submit">Upload Documents</Button>
            </form>
        </div>
    );
};

export default Upload;
