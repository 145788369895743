import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import ThemeContext from "../../store/themeContext";
import "./AssignTask.css";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";

interface Employee {
    id: string;
    name: string;
}

const AssignTask: React.FC = () => {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { theme, toggleTheme } = useContext(ThemeContext);

    const [selectedEmployee, setSelectedEmployee] = useState<string>("");
    const [selectedViewers, setSelectedViewers] = useState<string[]>([]);
    const [task, setTask] = useState<string>("");
    const [doa, setDoa] = useState<string>("");
    const [deadline, setDeadline] = useState<string>("");
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${API_URL}/Admin/Fetch/EMP_HIRED`, {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                    },
                });
                setEmployees(response.data.msg);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch employees.");
                setLoading(false);
            }
        };

        fetchEmployees();
    }, [API_URL]);

    const handleAssignTask = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${API_URL}/Admin/Routes/AssignTask`,
                {
                    id: selectedEmployee,
                    viewers: selectedViewers.join(","), // Concatenate viewers into comma-separated string
                    task,
                    doa,
                    deadline,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.error === "0") {
                alert("Task assigned successfully!");
                setSelectedEmployee("");
                setSelectedViewers([]);
                setTask("");
                setDoa("");
                setDeadline("");
            } else {
                setError(response.data.msg);
            }
        } catch (err) {
            setError("Failed to assign task.");
        }
    };

    const handleViewerSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const viewerId = e.target.value;
        if (viewerId && !selectedViewers.includes(viewerId)) {
            setSelectedViewers([...selectedViewers, viewerId]);
        }
    };

    return (
        <div className={`assign-task ${theme}`}>
            <button onClick={toggleTheme} style={{background: 'transparent', border: 'none', cursor: 'none'}}></button>
            <h1>TASK ASSIGNMENT</h1>
            {loading ? (
                <LoadingSpinner/>
            ) : error ? (
                <p className="error">{error}</p>
            ) : (
                <form onSubmit={handleAssignTask}>
                    <label>
                        Assign To:
                        <select value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)} required>
                            <option value="">Select an employee</option>
                            {employees.map((employee) => (
                                <option key={employee.id} value={employee.id}>
                                    {employee.name} - {employee.id}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label>
                        Add Viewers:
                        <select value="" onChange={handleViewerSelection}>
                            <option value="">Select a viewer</option>
                            {employees
                                .filter((emp) => emp.id !== selectedEmployee && !selectedViewers.includes(emp.id))
                                .map((employee) => (
                                    <option key={employee.id} value={employee.id}>
                                        {employee.name} - {employee.id}
                                    </option>
                                ))}
                        </select>
                    </label>
                    {/* Display the selected viewers */}
                    {selectedViewers.length > 0 && (
                        <div className="selected-viewers">
                            <h3>Selected Viewers:</h3>
                            <ul>
                                {selectedViewers.map((viewerId) => {
                                    const viewer = employees.find((emp) => emp.id === viewerId);
                                    return viewer ? <li key={viewerId}>{viewer.name} - {viewer.id}</li> : null;
                                })}
                            </ul>
                        </div>
                    )}
                    <div className="date-inputs">
                        <label>
                            Date of Assignment (DOA):
                            <input type="date" value={doa} onChange={(e) => setDoa(e.target.value)} required/>
                        </label>
                        <label>
                            Deadline:
                            <input type="date" value={deadline} onChange={(e) => setDeadline(e.target.value)} required/>
                        </label>
                    </div>
                    <label>
                        Task:
                        <textarea value={task} onChange={(e) => setTask(e.target.value)} required/>
                    </label>
                    <button type="submit">Assign Task</button>
                </form>
            )}
        </div>
    );
};

export default AssignTask;
