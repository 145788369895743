import React, { useRef, useState } from "react";
// @ts-ignore
import classes from "../Login/Login.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/UI/button/Button";

function SignUpTwo() {
    const idRef = useRef<HTMLInputElement>(null);
    const designationRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLInputElement>(null);
    const bankAccNoRef = useRef<HTMLInputElement>(null);
    const ifscRef = useRef<HTMLInputElement>(null);
    const dobRef = useRef<HTMLInputElement>(null);
    const errorMessageRef = useRef<HTMLSpanElement>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    async function signUpHandler() {
        const id = idRef.current?.value;
        const designation = designationRef.current?.value;
        const phone = phoneRef.current?.value;
        const bank = bankRef.current?.value;
        const bankAccNo = bankAccNoRef.current?.value;
        const ifsc = ifscRef.current?.value;
        const dob = dobRef.current?.value;

        if (!id || !designation || !phone || !bank || !bankAccNo || !ifsc || !dob) {
            setErrorMessage("All fields are required.");
            return;
        }

        try {
            let API_URL = process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/Employee/Register/EMP_REG_SECOND`, {
                method: "POST",
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({
                    id, designation, phone, bank, bankAccNo, ifsc, dob
                }),
            });

            if (!response.ok) {
                throw new Error("Registration failed. Please try again.");
            }

            const data = await response.json();
            if (data.error === '0') {
                navigate("/login");
            } else {
                setErrorMessage("Registration failed. Please check your details.");
            }
        } catch (error: any) {
            setErrorMessage(error.message || "Something went wrong. Please try again.");
        }
    }

    return (
        <div className={`${classes.container}`}>
            <div className={classes.loginBox} style={{width:'30%'}}>
                <div className={classes.iitLogo}>
                    <img src={require("../../assets/images/IIT K Logo.png")} alt="IIT Logo" style={{ width: '150px' }} />
                </div>

                <h4 className={classes.title}>{"Complete your registration"}</h4>

                <input ref={idRef} type="text" placeholder="ID" />
                <input ref={designationRef} type="text" placeholder="Designation" />
                <input ref={phoneRef} type="tel" placeholder="Phone" />
                <input ref={bankRef} type="text" placeholder="Bank" />
                <input ref={bankAccNoRef} type="text" placeholder="Bank Account Number" />
                <input ref={ifscRef} type="text" placeholder="IFSC Code" />
                <input ref={dobRef} type="date" placeholder="Date of Birth" />

                {errorMessage && (
                    <span ref={errorMessageRef} className={classes.errorMessage}>
                        {errorMessage}
                    </span>
                )}

                <Button type="button" onClick={signUpHandler}>{"Submit Details"}</Button>

                <p className={classes.signup}>{"Already have an account?"} &nbsp;
                    <Link className={classes.forgat_pass} to='/login'>Login</Link>
                </p>
            </div>

            <div className={classes.keyPic}>
                <img src={require("../../assets/images/virtual labs.png")} alt="Virtual Labs Logo" className={classes.virtualLabsLogo} style={{ width: '550px', height: '400px', marginLeft: '60px' }} />
            </div>
        </div>
    );
}

export default SignUpTwo;